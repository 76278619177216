import axios from "axios";
import store from "../store/index";
import router from "../router/index";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response.status === 401) {
      store.commit("logout");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  function (config) {
    const token = store.state.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
